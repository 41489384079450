import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {graphql} from "gatsby";
import CategoriesPage from "../components/category/categories";

const Categories = () => (
    <Layout>
        <Seo title="Categories" />
        <CategoriesPage/>
    </Layout>
)

export default Categories

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;