

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "gatsby"
import TitleLogo from "../title-logo/title-logo";
import { useI18next } from 'gatsby-plugin-react-i18next';

const CategoriesPage = (data) => {
    const post = data.restApiApiProducts;
    const [category, setCategory] = useState([]);
    const { language, languages, changeLanguage } = useI18next();

    useEffect(() => {
        if (localStorage.getItem('categories') != null) {
            setCategory(JSON.parse(localStorage.getItem('categories')));
        }
        axios.get(`https://cmsv2.dact.studio/api/categories/${language}`).then((response) => {
            setCategory(response.data);
            localStorage.setItem('categories', JSON.stringify(response.data));
        });

    }, []);
    return (
        <section className="categories-page">

            <div className="container-fluid text-center mb-5 mt-5" id="slide-button">

                {/* <div className="row" id="slide-botton">
                    {category.map((category, index) =>
                        <div className="col-lg-4 mx-0 px-0">
                            {category.product.map((product) =>
                                <Link to={'/product-detail/' + product.slug}>
                                    <h5 >{product.name}</h5>
                                    <div className="card-img-overlay">
                                    </div>
                                </Link>
                            )}

                        </div>
                    )}
                </div> */}
            </div>

        </section>
    )
}
export default CategoriesPage;

